@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
body {
    font-family: 'VT323', monospace;
    background: #7474d6;
    font-size: 20pt;
    color: #00ceff;
    min-width: 1200px !important;
}
.alignCenter {
    text-align: center;
}
.fullWidth {
    width: 100%;
}
.buttonDeny {
    width: 145px;
    height: 35px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #aaa;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: not-allowed;
    outline: none;
    margin: 0 5px;
}
.buttonMenu {
    width: 145px;
    height: 35px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #afa;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: not-allowed;
    outline: none;
    margin: 0 5px;
}
.buttonMint {
    width: 280px;
    height: 60px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #afa;
    border: none;
    border-radius: 25px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: not-allowed;
    outline: none;
    margin: 20px 5px;
}
.buttonMenu:hover, .buttonMint:hover, .buttonChangePenguin:hover {
    background-color: #2EE59D;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: scale(1.1,1.1);
}
.buttonMenu:focus, .buttonChangePenguin:focus {
    background-color: #afa;
    color: #000;
    box-shadow: none; /*0px 15px 20px rgba(46, 229, 157, 0.4);*/
}
.buttonDeny:focus {
    background-color: #aaa;
    color: #000;
    box-shadow: none; /*0px 15px 20px rgba(46, 229, 157, 0.4);*/
}
.buttonDeny:hover {
    cursor: not-allowed;
    background-color: #444;
    color: #fff;
}
.buttonChangePenguin {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #afa;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: not-allowed;
    outline: none;
    padding: 5px 20px;
}

a {
    color: #00ceff;;
}
a:hover {
    color: #99ceff;;
}
img {
    margin-top: 10px;
}
.miningImg {
    width: 93%; /*280px;*/
    padding: 0;
    margin: 9px 9px 0 9px;
    border: 1px solid #9494f6;
    border-radius: 5px;
    background-image: linear-gradient(white, green);
}
.miningImgMining {
    width: 93%; /*280px;*/
    padding: 0;
    margin: 9px 9px 0 9px;
    border: 1px solid #9494f6;
    border-radius: 5px;
	background: linear-gradient(-45deg, #44aa00, #00ffff, #094B79, #23d5bb);
	background-size: 200% 200%;
	animation: gradient 4s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.exploreImg {
    transform: scale(0.8);
    transition-duration: 1.5s;
    width: 350px;
    padding: 0;
    margin: 3px 0 20px 0;
    border: 3px solid #9494f6;
    border-radius: 5px;
    background-image: linear-gradient(white, green);
}
.exploreImg:hover {
    transition-duration: 0.5s;
    transform: rotateX(0deg) scale(1.1);
}
.soldOutImg{
    transform: scale(0.95);
    transition-duration: 1.5s;
    width: 100%;
    padding: 0;
    margin: 3px 0 20px 0;
}
.soldOutImg:hover {
    transition-duration: 0.5s;
    transform: rotateX(0deg) scale(1.05);
}
.mapImg {
    transform: scale(0.95);
    transition-duration: 1.5s;
    width: 1224px;
    padding: 0;
    margin: 3px 0 20px 0;
}
.mapImg:hover {
    transition-duration: 0.5s;
    transform: rotateX(0deg) scale(1.05);
}
.roadmapImg {
    width: 100%;
    padding: 0;
    margin: 3px 0 20px 0;
    border-radius: 10px;
}
.alert-info, .alert-warning {
    padding: 0px 5px;
    font-size: 18pt;
    margin-bottom: 0;
    border-radius: 5px 5px 0 0;
}
.table {
    font-size: 16pt;
    background-color: #cff4fc;
}
th {
    font-weight: normal;
    background-color: red;
}
.active {
    padding: 1px 0 20px 0;
    border-bottom: 6px dotted;
    transition-duration: 0.2s;
}
.btn-close {
    width: 5px;
    height: 10px;
}
.modal-content {
    background-color: #cff4fc;
    color: black
}
.miningCard {
    background: linear-gradient(135deg, rgba(25, 190, 155, 1) 0%, rgba(140,
    70, 175, 1) 100%);
    margin: 5px 0;
    padding: 0;
    /*width: 255px;*/
}
.miningMap {
    background: linear-gradient(135deg, rgba(25, 120, 155, 1) 0%, rgba(140,
    70, 175, 1) 100%);
    width: 255px;
    height: 151px;
    margin: 0 0 15px 0;
}
.miningMap:hover {
    cursor: pointer;
}
.miningOption {
    background: linear-gradient(135deg, rgba(25, 120, 155, 1) 0%, rgba(140,
    70, 175, 1) 100%);
    height: 120px;
    margin: 5px 10px 10px 10px;
    padding: 5px 10px;
    font-size: 12pt;
    display: block !important;
}
.miningMapSelected {
    background: linear-gradient(135deg, rgba(40, 150, 175, 1) 0%, rgba(160,
    90, 195, 1) 100%);
    width: 250px;
    height: 151px;
    margin-bottom: 10px;
    border-width: 5px;
    border-color: coral;
}
.miningTitle {
    font-size: 16pt;
}
.miningBody {
    font-size: 14pt;
}
.miningButton {
    background-color: inherit;
    width: 100%;
    height: 60px;
    margin: auto;
}
.miningStatus {
    background-color: inherit;
    width: 100%;
    height: 60px;
    margin: auto;
    font-size: 15pt;
    color: #00ceff;
}
.miningProgressTip {
    background-color: #555;
}
.miningLandSelect {
    width: 115px;
    margin-top: 4px;
}
.miningHelp {
    font-size: 14pt;
    margin: 0 5px 20px 5px;
    padding: 5px 10px;
    border-radius: 5px;
}
.miningStakeAddress {
    margin: 0 5px;
}
.miningAddressContainer, .form-control:disabled {
    background-color: #badbcc;
}
.cursorPointer {
    cursor: pointer;
}
.base {
    background-color: #cff4fc;
    color: #055160;
}
.goldText {
    display: inline;
    color: #FFD700;
}
.pLogEntry {
    font-size: 15pt;
    line-height: 15px;
}